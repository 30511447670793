import { Component } from '@angular/core';

@Component({
  selector: 'milestone-tracking-order',
  templateUrl: './tracking-order.component.html',
  styleUrls: ['./tracking-order.component.scss']
})
export class TrackingOrderComponent {

}
