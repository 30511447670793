import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps'; // Import GoogleMap from Angular Maps

import { Subscription } from 'rxjs';
import { WebsocketService } from 'src/app/core/config/websocket.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { LocationOperator } from './model/location-operator.model';

@Component({
  selector: 'milestone-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit, OnDestroy {
  
  @ViewChild(GoogleMap, { static: false }) googleMap!: GoogleMap; // Reference to the GoogleMap component

  messageSubscription: Subscription | null = null;
  center: google.maps.LatLngLiteral = AppConstant.center;
  zoom = AppConstant.zome;

  operatorPolylines: Map<string, google.maps.Polyline> = new Map(); // Map of sender to polyline
  operatorMarkers: Map<string, google.maps.marker.AdvancedMarkerElement> =
    new Map(); // Map of sender to AdvancedMarkerElement

  // Marker icon (using the delivery.svg)
  iconUrl = 'assets/content/images/location/delivery.svg';

  mapOptions: google.maps.MapOptions = {
    gestureHandling: 'cooperative',
    center: this.center,
    zoom: this.zoom,
    mapTypeId: google.maps.MapTypeId.TERRAIN,
    mapId: AppConstant.googleMapId,
  };

  constructor(private wsService: WebsocketService) {}

  ngOnInit(): void {
    this.wsService.subscribeToUser('mile.stone');
    this.messageSubscription = this.wsService
      .onLocation()
      .subscribe((location: LocationOperator) => {
        this.handleLocationUpdate(location);
      });
  }

  // Function to simulate sending a fake position
  updatePosition(): void {
    this.wsService.sendMessage('/app/location', {
      sender: 'Hamzs',
      receiver: 'mile.stone',
      latitude: 34.45842264399145,
      longitude: -3.2866007808930875,
    });
  }

  // Handle map move event
  moveMap(event: google.maps.MapMouseEvent): void {
    if (event.latLng) {
      this.center = event.latLng.toJSON();
    }
  }

  // Handle zoom event
  onZoom(): void {
    this.zoom = this.googleMap.getZoom() ?? this.zoom;
  }

  ngOnDestroy(): void {
    // Clean up WebSocket subscription
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  private handleLocationUpdate(operator: LocationOperator): void {
    const sender = operator.sender;
    const position = new google.maps.LatLng(
      +operator.latitude,
      +operator.longitude
    );

    // Check if the sender already has a polyline
    let polyline = this.operatorPolylines.get(sender);
    if (!polyline) {
      // Create a new polyline if it doesn't exist for this sender
      polyline = new google.maps.Polyline({
        path: [position], // initialize with the first position
        strokeColor: this.getRandomColor(),
        strokeOpacity: 1.0,
        strokeWeight: 4,
        map: this.googleMap.googleMap!, // Use the googleMap instance properly
      });
      this.operatorPolylines.set(sender, polyline);
    } else {
      // Update existing polyline by adding the new position
      const path = polyline.getPath();
      path.push(position);
    }

    // Check if the sender already has an AdvancedMarkerElement
    let marker = this.operatorMarkers.get(sender);
    if (!marker) {
      // Create a new AdvancedMarkerElement if it doesn't exist for this sender
      const iconElement = document.createElement('div');
      iconElement.innerHTML = `<img src="${this.iconUrl}" style="width: 35px; height: 35px;" />`;

      marker = new google.maps.marker.AdvancedMarkerElement({
        position, // initial position
        map: this.googleMap.googleMap!, // attach the marker to the map
        content: iconElement, // use the custom icon as a DOM element
        title: sender, // optional, displays when hovering over the marker
      });
      this.operatorMarkers.set(sender, marker);
    } else {
      // Update existing marker's position
      marker.position = position;
    }
  }

  // Generate a random color for each sender's polyline
  private getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}

// @Component({
//   selector: 'milestone-location',
//   templateUrl: './location.component.html',
//   styleUrls: ['./location.component.scss'],
// })
// export class LocationComponent implements OnInit {
// @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
// @ViewChild('googleMap') googleMap!: google.maps.Map;

//   operatorPositions: IOperatorPosition[] = [];
//   operatorPosition?: OperatorPosition | null;
//   messageSubscription: Subscription | null = null;
//   accountUsers$ = new Subject<AccountUser[]>();
//   users: AccountUser[] = [];
//   selectedOperator = '';
//   selectedAccount?: AccountUser;

//   stompClient: any;
//   positionMarkerInfo: any;
// zoom = AppConstant.zome;
//   center: google.maps.LatLngLiteral = AppConstant.center;
//   trackingDelivry: google.maps.LatLngLiteral[] = [];
//   polylineOptions: google.maps.PolylineOptions = this.initPolylineOptions();

//   mapOptions: google.maps.MapOptions = {
//     gestureHandling: 'cooperative',
//     center: this.center,
//     zoom: this.zoom,
//     mapTypeId: google.maps.MapTypeId.TERRAIN,
//   };

// icon = {
//   url: 'assets/content/images/location/delivery.svg',
//   scaledSize: new google.maps.Size(35, 35),
//   origin: new google.maps.Point(0, 0),
//   anchor: new google.maps.Point(0, 0),
// };

//   startDateControl = new FormControl(new Date());
//   endDateControl = new FormControl(new Date());
//   color: ThemePalette = 'warn';

//   constructor(
//     private wsService: WebsocketService,
//     private operatorPositionService: LocationPositionService,
//     private notificationService: NotificationService,
//     private accountService: AccountService,
//     private trackingService: TrackingOperatorService
//   ) {}

//   ngOnInit(): void {
//     this.loadUsers();
//     this.wsService.subscribeToUser('mile.stone');

//     this.messageSubscription = this.wsService
//       .onLocation()
//       .subscribe((location: LocationOperator) => {
//         console.log(location.receiver);
//         this.setTrackingDelivry(location);
//       });
//   }

//   loadUsers(): void {
//     this.accountService.getAllUsers().subscribe((users) => {
//       this.users = users;
//       this.accountUsers$.next(users);
//     });
//   }

// moveMap(event: google.maps.MapMouseEvent): void {
//   if (event.latLng) {
//     this.center = event.latLng.toJSON();
//   }
// }

//   onZoom(): void {
//     this.zoom = this.googleMap.getZoom() ?? this.zoom;
//   }

//   openInfoWindow(marker: MapMarker, positionInfo: any): void {
//     if (this.infoWindow) {
//       this.positionMarkerInfo = positionInfo;
//       this.infoWindow.open(marker);
//     }
//   }

//   OnSelectOperator(account: AccountUser): void {
//     this.selectedAccount = account;
//     this.selectedOperator = `${account.firstname} ${
//       account.lastModifiedBy ?? ''
//     } - ${account.cin ?? ''}`;
//     if (this.selectedAccount.id) {
//       // this.wsService.subscribeToUser(this.selectedAccount.username);
//       this.wsService.subscribeToUser('mile.stone');
//     }
//   }

// updatePosition(): void {
//   this.wsService.sendMessage('/app/location', {
//     sender: 'Hamzs',
//     receiver: 'mile.stone',
//     latitude: 34.45842264399145,
//     longitude: -3.2866007808930875,
//   });
// }

//   private setTrackingDelivry(operator: LocationOperator): void {
//     this.trackingDelivry.push({
//       lat: +operator.latitude,
//       lng: +operator.longitude,
//     });
//   }

//   private initPolylineOptions(): google.maps.PolylineOptions {
//     return {
//       path: this.trackingDelivry,
//       strokeColor: '#32a1d0',
//       strokeOpacity: 3.0,
//       strokeWeight: 4,
//     };
//   }

//   private initMap(center: google.maps.LatLngLiteral): void {
//     this.mapOptions = {
//       gestureHandling: 'cooperative',
//       center,
//       zoom: this.zoom,
//       mapTypeId: google.maps.MapTypeId.TERRAIN,
//     };
//   }

//   private showOperatorPosition(resp: OperatorPosition): void {
//     this.operatorPosition = resp;
//     if (this.operatorPosition.lat && this.operatorPosition.lng) {
//       this.initMap({
//         lat: +this.operatorPosition.lat,
//         lng: +this.operatorPosition.lng,
//       });
//       this.operatorPositions = [this.operatorPosition];
//     }
//   }

//   ngOnDestroy(): void {
//     if (this.messageSubscription) {
//       this.messageSubscription.unsubscribe();
//     }
//   }

// }
