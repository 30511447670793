import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebare/sidebar.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/service/login.service';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { AccountService } from './../../modules/user/services/user.service';
import { UserVM } from './../../modules/user/models/user-vm.model';

@Component({
  selector: 'milestone-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: UserVM | null = null;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private sidebarService: SidebarService,
    private clientDataStorgare: ClientDataStorageService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    if (this.clientDataStorgare.isAutenticated()) {
      this.loadUserDetails();
    }
  }

  async loadUserDetails(): Promise<void> {
    try {
      this.user = await this.accountService.getUserDetails();
    } catch (error) {
      // Handle the rejected promise (error)
      console.error("Failed to fetch user details:", error);
    }
  }
  

  toggleSidebar(): void {
    this.sidebarService.toggleSidebar();
  }

  async logout(): Promise<void> {
    console.log('logout >>> ');
    this.loginService.logout();

    const navigationExtras = { replaceUrl: true };
    await this.router.navigateByUrl('', navigationExtras);

    location.reload();
    window.location.reload();
  }
}
