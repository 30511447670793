import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfigService } from './../../../core/config/application-config.service';
import { AppConstant } from './../../../shared/app-constant';
import { IShipmentVM, ShipmentPagenationsVM } from '../models/shipment-vm.model';
import { ITrackingShipmentVM } from '../models/tracking-shipment-vm.model';
import { Pagination } from './../../../core/request/request.model';
import { RequestSearch } from './../../../core/specification/requestSearch';
import { createRequestOption } from './../../../core/request/request-util';
import { Indicator } from './../../../shared/indicator-card/indicator-card.model';

@Injectable({
  providedIn: 'root',
})
export class ShipementService {
  constructor(
    private http: HttpClient,
    private applicationService: ApplicationConfigService
  ) {}

  getdetailShipment(idShipement: number): Observable<IShipmentVM> {
    return this.http.get<IShipmentVM>(
      this.applicationService.getEndpointFor(`/shipments/by-id-shipment/${idShipement}`),
      AppConstant.httpOptions
    );
  }

  getTrackingShipment(idShipement: number): Observable<ITrackingShipmentVM[]> {
    return this.http.get<ITrackingShipmentVM[]>(
      this.applicationService.getEndpointFor(`/tracking-shipments/${idShipement}`),
      AppConstant.httpOptions
    );
  }

  query( pagination: Pagination, filters?: RequestSearch | null): Observable<ShipmentPagenationsVM> {
    const paramsQuery = createRequestOption(pagination);
    return this.http
      .post<ShipmentPagenationsVM>(
        this.applicationService.getEndpointFor(`/shipments/query`),
        filters,
        {headers: AppConstant.httpOptions.headers, params: paramsQuery}
      );
  }

  getIdicatorsPickup(): Observable<Indicator[]> {
    return this.http
      .get<Indicator[]>(
        this.applicationService.getEndpointFor(`/shipments/indicators-pickup`),
        {headers: AppConstant.httpOptions.headers}
      );
  }

}
