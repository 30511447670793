import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { StateStorageService } from './state-storage.service';
import { AccountService } from 'src/app/modules/user/services/user.service';

const URLS = ["/login"];

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private tokenStorageService: ClientDataStorageService,
     private router: Router,
      private stateStorageService: StateStorageService,
      private accountService: AccountService
    ) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          sessionStorage.setItem('currentRoute', event.urlAfterRedirects);
        }
      });
     }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const intendedUrl: string = state.url; // Get the intended URL from the state   
    this.stateStorageService.storeUrl(intendedUrl);
    if (!this.tokenStorageService.isAutenticated()) {
      if (URLS.includes(intendedUrl)) { return true; }
      this.router.navigate(['/login']);
      return false;
    } else {
      const permissions = route.data['permissions'];
      if(!permissions || permissions.length === 0 || this.accountService.hasAnyAuthority(permissions)){
        return true;
      }
      return this.isAuthorized(permissions);
    }
  }

  isAuthorized(permissions: string[] | string): boolean {
    const result = this.accountService.hasAnyAuthority(permissions);
    console.log("isAuthorized>> result is roles includes permissions : ", result);
    return result;
  }
}