<div class="row mt-2">
  <div class="col-md-9">
    <div class="card" style="border-left: 9px solid #385888; padding-top: 15px">
      <div class="card-body">
        <div class="bloc_ordres">
          <div class="item_separ">
            <p class="title_details">Ref. Ordre:</p>
            <span class="value_details">{{ idOrder }}</span>
          </div>
          <div class="item_separ">
            <span *ngIf="pickup?.address !== null; else adresse">
              <div class="content_bloc">
                <div>
                  <img src="assets/images/typedev.png" alt="" />
                </div>
                <div>
                  <p class="title_details">Point de ramassage:</p>
                  <span class="value_details">{{ pickup?.address }}</span>
                </div>
              </div>
            </span>
            <ng-template #adresse>
              <div class="content_bloc">
                <div style="text-align: center">
                  <img src="assets/images/smartlocker.svg" alt="" />
                </div>
                <div>
                  <p class="title_details">Consigne:</p>
                  <span class="value_details">{{
                    pickup?.smartLocker?.idSmartLocker
                  }}</span>
                </div>
              </div>
            </ng-template>
          </div>

          <div class="item_separ">
            <div class="content_bloc">
              <div>
                <img src="assets/images/livreur.png" alt="" />
              </div>
              <div>
                <p class="title_details">
                Ramasseur:             
                </p>
                <span class="value_details">
                  {{
                    pickup?.user?.firstname !== null
                      ? pickup?.user?.firstname
                      : "-"
                  }}
                  {{ pickup?.user?.lastname }}<br>
               {{(pickup?.user?.mobile !== null)
                    ? replace002With0(pickup?.user?.mobile)
                    : "-"
                }}</span>
              </div>
            </div>
          </div>

          <div class="item_separ">
            <div class="content_bloc">
              <div>
                <img src="assets/images/box-solid.png" alt="" />
              </div>
              <div>
                <p class="title_details">Nombre de colis:</p>
                <span class="value_details">{{
                  pickup?.currentCountShipment
                }} colis</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-2">
      <div *ngIf="pickup?.user === null;">
        <button
          (click)="openPopup(idOrder)"
          class="btn-action-modal"
          aria-hidden="true"
          style="padding: 8px 16px;width: 80%;"
        >
          à affecter
        </button></div>
      </div>
      <div class="col-md-10 d-flex justify-content-end gap-2">   
        <!-- Incident Button -->
        <button
          type="button"
          class="btn btn-outline-danger bg-white d-flex flex-wrap justify-content-between gap-2 btn-order"
        >
          <i _ngcontent-ng-c1766591684="" class="material-icons">warning</i>
          {{indicatorOrderDetails?.countShipmentIncident}} <br />Incident
        </button>

        <!-- collected Button -->
         <button
          type="button"
          class="btn btn-outline-info bg-white d-flex align-items-center btn-order"
        >
          <i _ngcontent-ng-c1766591684="" class="material-icons">list</i>
          {{indicatorOrderDetails?.countShipmentCollected}}
           <br />Collecté&nbsp;
        </button>

        <!-- Terminée Button -->
        <button
          type="button"
          class="btn btn-outline-success bg-white d-flex align-items-center btn-order"
        >
          <i _ngcontent-ng-c1766591684="" class="material-icons">
            check_circle
          </i>
          {{indicatorOrderDetails?.countShipmentTerminated}}
           <br />Pointé
        </button>
      </div>
    </div>

    <div class="mt-4">
      <div class="row mb-3">
     <!--   <div class="col-md-2">
        <mat-form-field appearance="fill" class="custom-form-field">
          <mat-label>Choisir une date</mat-label>
          <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
              (dateChange)="onFiltersChange()"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="End date"
              (dateChange)="onFiltersChange()"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker
        
          ></mat-date-range-picker>
        </mat-form-field>
          <input
            id="touchUiCheckbox"
            type="checkbox"
            (change)="isTouchUIActivated = !isTouchUIActivated"
          />
        </div>-->
      <div class="col-md-2">
        <div class="search-box">
          <input
            type="number"
            class="form-control"
            id="refOrdre"
            placeholder="Réf. colis"
            [(ngModel)]="idShipement"
            (keyup)="onFiltersChange()"
          />
          <!-- <i class="fas fa-search"></i> -->
        </div>
      </div>

      <div class="col-md-2">
        <div class="search-box">
          <input
            type="number"
            class="form-control"
            id="idParcelClient"
            placeholder="Réf. client"
            [(ngModel)]="idParcelClient"
            (keyup)="onFiltersChange()"
          />
          <!-- <i class="fas fa-search"></i> -->
        </div>
      </div>

      <!-- city -->
      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Ville de départ</mat-label>
          <mat-select
            [(ngModel)]="cityDeparture"
            (ngModelChange)="onFiltersChange()"
          >
            <mat-option *ngFor="let city of cities" [value]="city.cityName">
              {{ city.cityName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-2">
        <mat-form-field>
          <mat-label>Ville d'arrivé </mat-label>
          <mat-select
            [(ngModel)]="cityArrival"
            (ngModelChange)="onFiltersChange()"
          >
            <mat-option *ngFor="let city of cities" [value]="city.cityName">
              {{ city.cityName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Point de Livraison -->
      <div class="col-md-2">
        <div class="search-box">
          <input
            type="text"
            class="form-control"
            id="client"
            placeholder="Client"
            [(ngModel)]="clientName"
            (keyup)="onFiltersChange()"
          />
          <!-- <i class="fas fa-search"></i> -->
        </div>
      </div>

      <!-- Statut -->
      <div class="col-md-2">
        <select
          class="form-select"
          id="statut"
          [(ngModel)]="selectedSatatus"
          (change)="onFiltersChange()"
          [ngModelOptions]="{ standalone: true }"
        >
          <option selected value="">Statut</option>
          <option
            *ngFor="let statut of statutShipment"
            [value]="statut.codesTracking"
          >
            {{ statut.description }}
          </option>
        </select>
      </div>
    </div>
    </div>

    <div class="">
      <div class="example-container" #TABLE>
        <table mat-table #table [dataSource]="dataSource" class="table">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef style="width: 3%">
              <mat-checkbox
                class="example-margin"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <!-- ID Column -->

          <ng-container
            matColumnDef="Date de création"
            *ngIf="isVisible('Date de création')"
          >
            <th mat-header-cell *matHeaderCellDef>Date de création</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdDate * 1000 | date : "dd-MM-YYYY" }} <br />
              {{ element.createdDate * 1000 | date : "HH:mm:ss" }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="Référence expédition"
            *ngIf="isVisible('Référence expédition')"
          >
            <th mat-header-cell *matHeaderCellDef>Référence d'expédition</th>
            <td mat-cell *matCellDef="let element">
              <a
                [routerLink]="[
                  '/shipement-details',
                  element.shipment.idShipment
                ]"
                >{{ element.shipment.idShipment }}</a
              >
            </td>
          </ng-container>

          <ng-container
          matColumnDef="Référence client"
          *ngIf="isVisible('Référence client')"
        >
          <th mat-header-cell *matHeaderCellDef>Référence client</th>
          <td mat-cell *matCellDef="let element">
            <a
              [routerLink]="[
                '/shipement-details',
                element.shipment.idShipment
              ]"
              >{{ element.shipment.idParcelClient }}</a
            >
          </td>
        </ng-container>

          <ng-container
            matColumnDef="Ville depart"
            *ngIf="isVisible('Ville depart')"
          >
            <th mat-header-cell *matHeaderCellDef>Ville de départ</th>
            <td mat-cell *matCellDef="let element">
              {{ element.shipment.cityDeparture.cityName }}
            </td>
          </ng-container>

          <ng-container
            matColumnDef="Ville arrivee"
            *ngIf="isVisible('Ville arrivee')"
          >
            <th mat-header-cell *matHeaderCellDef>Ville d'arrivé</th>
            <td mat-cell *matCellDef="let element">
              {{ element.shipment.cityArrival.cityName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Client" *ngIf="isVisible('Client')">
            <th mat-header-cell *matHeaderCellDef>Client</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.shipment.companySender === ""
                  ? "-"
                  : element.shipment.companySender
              }}
              <br />
              {{ element.shipment.gsmSender.replace("00212", "0") }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Statut" *ngIf="isVisible('Statut')">
            <th mat-header-cell *matHeaderCellDef style="width: 15%">Statut</th>
            <td mat-cell *matCellDef="let element">
              <span class="{{(element.shipment.currentStatus.codesTracking.includes('ISSUE') || element.shipment.currentStatus.codesTracking.includes('REFUSED')) ? 'Incident_statut' : ''}}">
                 {{ element.shipment.currentStatus.description }}
                 </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              <button (click)="toggleDisplayDivIf()">
                <iconify-icon icon="ant-design:setting-filled"></iconify-icon>
              </button>

              <div *ngIf="isShowDivIf" class="togglecolumn">
                <mat-selection-list [(ngModel)]="visibleColumns">
                  <mat-list-option
                    *ngFor="let column of allColumns; let i = index"
                    [value]="column"
                    style="--mdc-checkbox-selected-icon-color: #1e3a8a"
                  >
                    {{ column }}
                  </mat-list-option>
                </mat-selection-list>
              </div>
            </th>

            <td mat-cell *matCellDef="let element"></td>
          </ng-container>

          <!-- Name Column -->

          <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: visibleColumns"></tr>
        </table>
        <span
          *ngIf="totalItems === 0"
          style="display: table; margin: 10px auto"
        >
          Aucune données trouvées
        </span>
      </div>
      <mat-paginator
        *ngIf="totalItems !== 0"
        [length]="totalItems"
        [pageSize]="pageSize"
        [pageSizeOptions]="[1, 2, 5, 10, 20]"
        [showFirstLastButtons]="true" 
        aria-label="{{ 'itemsParPage' }}"
        (page)="onTableDataChange($event)"
      ></mat-paginator>
      <div></div>
      <button *ngIf="totalItems !== 0" (click)="ExportToExcel()">
        <img src="assets/images/excel.svg" alt="export" width="40px" />
      </button>&nbsp;
      <button *ngIf="totalItems !== 0" (click)="exportToPDF()">
        <img src="assets/images/pdf.svg" alt="export" width="40px" />
      </button>
    </div>
  </div>

  <div class="col-md-3">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <span class="title_accord">Suivi de l'ordre</span>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>

          <hr style="margin-top: -7px" />
          <div [ngClass]="trackingOrders.length >= 4 ? 'scroll' : ''">
            <div class="history-tl-container">
              <ul class="tl" style="width: 100%">
                <li
                  *ngFor="let track of trackingOrders"
                  class="tl-item {{
                  (track.statusOrder.statusOrders.includes('ISSUE') || track.statusOrder.statusOrders.includes('REFUSED'))
                      ? 'Incident'
                      : track.statusOrder.statusOrders
                  }}"
                >
                  <div class="bloc_tracking">
                    <div class="item-title">
                      <p>
                        {{
                          "status_order." + track.statusOrder.statusOrders
                            | translate
                        }}
                      </p>
                    <span *ngIf="track.statusOrder.statusOrders?.includes('ISSUE') || track.statusOrder.statusOrders?.includes('REFUSED')">{{track.description}}</span>

                    </div>
                    <div class="timestamp">
                      <span
                        >{{ track.createdDate * 1000 | date : "dd-MM-YYYY" }}
                        {{ track.createdDate * 1000 | date : "HH:mm:ss" }}</span
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <span class="title_accord">Messages associés</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <hr style="margin-top: -7px" />
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <span class="title_accord">Documents</span>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <hr style="margin-top: -7px" />
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<!--<div class="history-tl-container">
  <ul class="tl" style="width: 100%">
    <li *ngFor="let tracking of trackingOrders"  class="tl-item {{
      incident.includes(tracking.statusOrder.statusOrders)
        ? 'Incident'
        : tracking.statusOrder.statusOrders
    }}">
      <milestone-tracking 
        [track]="tracking" class="track">
      </milestone-tracking>
      </li>
      </ul>-->
