import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import {
  Observable,
  catchError,
  lastValueFrom,
  map,
  tap,
  throwError,
} from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { UserType } from '../enum/User-type.enum';
import { AccountUserPagenationsVM } from '../models/user-paggination.model';
import { UserVM } from '../models/user-vm.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private currentUser: UserVM | null = null;

  constructor(
    private http: HttpClient,
    private applicationService: ApplicationConfigService,
    private dataStorage: ClientDataStorageService,
    private notification: NotificationService
  ) {}

  getUserDetails(): Promise<UserVM> {
    return lastValueFrom(
      this.http
        .get<UserVM>(
          this.applicationService.getEndpointFor('/users/account'),
          AppConstant.httpOptions
        )
        .pipe(
          tap((usr: UserVM) => {
            this.currentUser = usr;
            this.dataStorage.saveUser(usr);
          }),
          catchError((err) => {
            console.error('Error:', JSON.stringify(err));
            this.notification.showMessageError(err.message);
            // Re-throw the error to propagate it up the observable chain
            return throwError(err);
          })
        )
    );
  }

  getAllUsers(): Observable<UserVM[]> {
    return this.http
      .get<AccountUserPagenationsVM>(
        this.applicationService.getEndpointFor('/admin/users/query'),
        AppConstant.httpOptions
      )
      .pipe(
        map((accounts) => this.convertDateArrayFromServer(accounts.content))
      );
  }

  getUsersByFirstnameOrLastname(name: string): Observable<UserVM[]> {
    return this.http
      .get<UserVM[]>(
        this.applicationService.getEndpointFor(`/users/${name}`),
        AppConstant.httpOptions
      )
      .pipe(map((accounts) => this.convertDateArrayFromServer(accounts)));
  }

  getAllOperators(): Observable<UserVM[]> {
    return this.http
      .get<UserVM[]>(
        this.applicationService.getEndpointFor(`/admin/users/all`),
        AppConstant.httpOptions
      )
      .pipe(map((resp) => this.convertDateArrayFromServer(resp)));
  }

  getAllOperatorsByName(name: string): Observable<UserVM[]> {
    return this.http
      .get<UserVM[]>(
        this.applicationService.getEndpointFor(
          `/users/userType-name/${UserType.OPERATOR}/${name}`
        ),
        AppConstant.httpOptions
      )
      .pipe(map((resp) => this.convertDateArrayFromServer(resp)));
  }

  getOperateurs(): Observable<UserVM[]> {
    return this.http
      .get<UserVM[]>(this.applicationService.getEndpointFor('/users/operators'), AppConstant.httpOptions)
      .pipe(map((response) => response));
  }

  hasAnyAuthority(rights: string[] | string): boolean {
    if (!this.currentUser) {
      return false;
    }
    if (!Array.isArray(rights)) {
      rights = [rights];
    }
    return this.currentUser
      .getfunctions()
      .some((right: string) => rights.includes(right));
  }

  protected convertDateFromServer(res: UserVM): UserVM {
    res.createdDate = moment(res.createdDate);
    res.lastModifiedDate = moment(res.lastModifiedDate);
    return res;
  }

  protected convertDateArrayFromServer(res: UserVM[]): UserVM[] {
    res.forEach((data: UserVM) => {
      data.createdDate = moment(data.createdDate);
      data.lastModifiedDate = moment(data.lastModifiedDate);
    });

    return res;
  }
}
