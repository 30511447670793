import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LocationComponent } from './location/location.component';
import { LocationPositionService } from './location/service/location-position.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UsersComponent } from './user/user-list/users.component';
import { NotificationModule } from '../shared/notification/notification.module';
import { PermissionsModuleComponent } from './permissions-module/permissions-module.component';
import { CreatePermissionModuleFormComponent } from './permissions-module/create-permission-module-form/create-permission-module-form.component';
import { PermissionsFunctionsComponent } from './permissions-functions/permissions-functions.component';
import { CreatePermissionFunctionsFormComponent } from './permissions-functions/create-permission-functions-form/create-permission-functions-form.component';
import { ShelvingComponent } from './shelving/shelving.component';
import { CreateShelvingFormComponent } from './shelving/create-shelving-form/create-shelving-form.component';
import { EntityRoutingModule } from './entity-routing.module';
import { CodeTrackingComponent } from './code-tracking/code-tracking.component';
import { CreateCodeTrackingComponent } from './code-tracking/create-code-tracking/create-code-tracking.component';
import { LayoutService } from '../layout/layout.service';
import { StatusOrderComponent } from './status-order/status-order.component';
import { TypeOrderComponent } from './type-order/type-order.component';
import { SmartLockerComponent } from './smart-locker/smart-locker.component';
import { AccountComponent } from './user/account.component';
import { CreateUserFormComponent } from './user/create-user-form/create-user-form.component';
import { ZonesComponent } from './zone/zones.component';
import { CreateZoneComponent } from './zone/create-zone/create-zone.component';
import { AgenciesComponent } from './agency/agencies.component';
import { CreateAgencyFromComponent } from './agency/create-agency-from/create-agency-from.component';
import { CitiesComponent } from './city/cities.component';
import { CreateCityFromComponent } from './city/create-city-from/create-city-from.component';
import { SectorsComponent } from './sector/sectors.component';
import { CreateSectorFromComponent } from './sector/create-sector-from/create-sector-from.component';
import { UserTypesComponent } from './user-type/user-types.component';
import { CreateUserTypeFormComponent } from './user-type/create-user-type-form/create-user-type-form.component';
import { ShipmentDetailsComponent } from './shipment/shipment-details/shipment-details.component';
import { StatusCodComponent } from './status-cod/status-cod.component';
import { TypeDeliveryComponent } from './type-delivery/type-delivery.component';
import { TypeCollectComponent } from './type-collect/type-collect.component';
import { TrackingOrderComponent } from './tracking-order/tracking-order.component';
import { OrderComponent } from './order/order-list/order.component';
import { ShipmentListComponent } from './shipment/shipment-list/shipment-list.component';

@NgModule({
  declarations: [
    LocationComponent,
    DashboardComponent,
    AccountComponent,
    UsersComponent,
    CreateUserFormComponent,
    ZonesComponent,
    CreateZoneComponent,
    AgenciesComponent,
    CreateAgencyFromComponent,
    CitiesComponent,
    CreateCityFromComponent,
    SectorsComponent,
    CreateSectorFromComponent,
    PermissionsModuleComponent,
    CreatePermissionModuleFormComponent,
    PermissionsFunctionsComponent,
    CreatePermissionFunctionsFormComponent,
    UserTypesComponent,
    CreateUserTypeFormComponent,
    ShelvingComponent,
    CreateShelvingFormComponent,
    CodeTrackingComponent,
    CreateCodeTrackingComponent,
    ShipmentDetailsComponent,
    StatusOrderComponent,
    TypeOrderComponent,
    OrderComponent,
    SmartLockerComponent,
    StatusCodComponent,
    TypeDeliveryComponent,
    TypeCollectComponent,
    TrackingOrderComponent,
    ShipmentListComponent,

  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    MatSidenavModule,
    GoogleMapsModule,
    SharedModule,
    NotificationModule,
  ],
  exports: [EntityRoutingModule],

  providers: [LocationPositionService, LayoutService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModulesModule {}
