import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { CreatePermissionFunctionVM } from './../models/create_permission_functions_vm';
import { PermissionFunctionsPagenationsVM, PermissionsFunctionsVM } from './../models/permission_functions_vm';



@Injectable({
  providedIn: 'root',
})


export class PermissionFunctionsService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService,
    
  ) {}

  createPermissionFunctions(permissionFunction: CreatePermissionFunctionVM): Observable<void> {
    const data = {
      functions: permissionFunction.functions,
      module: { modules : permissionFunction.module},
      description: permissionFunction.description,
    };
    return this.http
      .post<PermissionsFunctionsVM>(
        this.applicationConfigService.getEndpointFor('/admin/functions'),
        data,
        AppConstant.httpOptions
      )
      .pipe(map((response) =>  console.log("response",response)));
  }

  getPermissionFunctions(): Observable<PermissionFunctionsPagenationsVM> {
    return this.http
      .get<PermissionFunctionsPagenationsVM>(this.applicationConfigService.getEndpointFor('/functions/all'), AppConstant.httpOptions)
      .pipe(map((response) => response));
  }







}
