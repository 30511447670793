import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/config/auth.guard.service';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login/login.component';
import { AgenciesComponent } from './modules/agency/agencies.component';
import { CitiesComponent } from './modules/city/cities.component';
import { CodeTrackingComponent } from './modules/code-tracking/code-tracking.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { LocationComponent } from './modules/location/location.component';
import { PermissionsFunctionsComponent } from './modules/permissions-functions/permissions-functions.component';
import { PermissionsModuleComponent } from './modules/permissions-module/permissions-module.component';
import { PickupDetailsOrdreComponent } from './modules/pickup-operations/pickup-details-ordre/pickup-details-ordre.component';
import { SectorsComponent } from './modules/sector/sectors.component';
import { ShelvingComponent } from './modules/shelving/shelving.component';
import { ShipmentDetailsComponent } from './modules/shipment/shipment-details/shipment-details.component';
import { ShipmentListComponent } from './modules/shipment/shipment-list/shipment-list.component';
import { UserTypesComponent } from './modules/user-type/user-types.component';
import { UsersComponent } from './modules/user/user-list/users.component';
import { ZonesComponent } from './modules/zone/zones.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'location',
        component: LocationComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'zones',
        component: ZonesComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'agencies',
        component: AgenciesComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'cities',
        component: CitiesComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'sectors',
        component: SectorsComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'modules',
        component: PermissionsModuleComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'functions',
        component: PermissionsFunctionsComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'profiles',
        loadChildren: () =>
          import('./modules/profile/profiles.module').then(
            (m) => m.ProfilesModule
          ),
      },
      {
        path: 'user-types',
        component: UserTypesComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'shelving',
        component: ShelvingComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'code-tracking',
        component: CodeTrackingComponent,
        canActivate: [AuthGuardService],
        data: { permissions: [] },
      },
      {
        path: 'pickup-operations',
        // canActivate: [AuthGuardService],
        loadChildren: () =>
          import('./modules/pickup-operations/pickup-operations.module').then(
            (m) => m.PickupOperationsModule
          ),
      },
      {
        path: 'pickup-operation-details/:idOrdre',
        canActivate: [AuthGuardService],
        component: PickupDetailsOrdreComponent,
      },
      {
        path: 'shipement-details/:idShipment',
        canActivate: [AuthGuardService],
        component: ShipmentDetailsComponent,
      },
      {
        path: 'shipment-list',
        canActivate: [AuthGuardService],
        component: ShipmentListComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },

  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgotpassword/forgotpassword.module').then(
        (m) => m.ForgotpasswordModule
      ),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
