import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from './../../../shared/notification/notification.service';
import { IOrderVM } from '../../order/models/order-vm.model';
import { OrderService } from '../../order/services/order-service.service';
import { UserVM } from '../../user/models/user-vm.model';
import { PickupConfirmAffectationComponent } from '../pickup-confirm-affectation/pickup-confirm-affectation.component';

interface Operator {
  idUser: number;
  firstname: string;
  lastname: string;
}

@Component({
  selector: 'milestone-pickup-details-popup',
  templateUrl: './pickup-details-popup.component.html',
  styleUrls: ['./pickup-details-popup.component.scss'],
})
export class PickupDetailsPopupComponent implements OnInit {
  
  @Input() infoModal: any;
  @Input() idOrder = '';
  @Input() operateurs: UserVM[] = [];
  isModalOpen = false;
  isconfirmModalOpen = false;
  selectedTeam = '';
  selectedTeamid = '';
  pickup?: IOrderVM;
  loading = false;
  constructor(
    protected activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private orderService: OrderService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.getorderByuserfile();
  }

  getorderByuserfile(): void {
    console.log(this.idOrder);
    this.orderService
      .getOrderByIdOrder(this.idOrder)
      .subscribe((response: any) => {
        this.pickup = response;
        console.log(this.pickup);
      },
       () => {
        this.notification.showMessageError('Error loading Details order');
      });
  }

  closeWithData(): void {
    this.activeModal.close();
  }

  openPopupAdresse(): void {
    this.isModalOpen = true;
  }

  handleFormSubmit(formData: any): void {
    console.log('Form Submitted', formData);
    this.isModalOpen = false;
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  handleClose2(): void {
    this.isconfirmModalOpen = false;
  }

  handleFormSubmit2(formData: any): void {
    console.log('Form Submitted', formData);
    this.isconfirmModalOpen = false;
  }

  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
  }

  onDelete(rowData: any): void {
    console.log('Delete clicked for row:', rowData);
  }

  confirmAffectation(): void {
    this.loading = true;
    this.orderService.assigning(this.idOrder, this.selectedTeamid).subscribe({
      next: (response: any) => {
        console.log(response);
       // this.loading = true;
        const modalRef = this.modalService.open(PickupConfirmAffectationComponent,
          {
            size: 'lg',
            centered: true,
            backdrop: 'static',
          }
        );
        this.activeModal.close('ok');
      },
      error: (err) => this.notification.showMessageError(err.message),
      
    });

  }

  onSelected(value: string, name: string): void {
    this.selectedTeam = name;
    this.selectedTeamid = value;
  }

  getOperatorName(id: number): string {
    const operator = this.operateurs.find((op: Operator) => op.idUser === id);
    return operator ? `${operator.firstname} ${operator.lastname}` : '';
  }

  replace002With0(input: string): string {
    return input.replace(/00212/g, '0');
  }
}
