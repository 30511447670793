import { Component, Input, OnInit, LOCALE_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { I18nTranslateService } from 'src/app/shared/utils/i18n-translate.service';
import { ITrackingShipmentVM } from '../models/tracking-shipment-vm.model';
import { IShipmentVM } from '../models/shipment-vm.model';
import { ShipementService } from '../services/shipement.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { FileService } from 'src/app/shared/utils/file.service';

registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'milestone-shipment-details',
  templateUrl: './shipment-details.component.html',
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
  styleUrls: ['./shipment-details.component.scss'],
})
export class ShipmentDetailsComponent implements OnInit {
  idShipment = '';
  isOpen = false;

  trackingShipments: ITrackingShipmentVM[] = [];

  shipment?: IShipmentVM;

  constructor(
    private route: ActivatedRoute,
    private shipementService: ShipementService,
    private notification: NotificationService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.idShipment = this.route.snapshot.paramMap.get('idShipment') ?? '';
    this.getdetailShipment();
    this.getTrackingShipment();
  }

  getdetailShipment(): void {
    this.shipementService.getdetailShipment(Number(this.idShipment)).subscribe({
      next: (response) => (this.shipment = response),
      error: (err) => this.notification.showMessageError(err.message),
    });
  }

  getTrackingShipment(): void {
    this.shipementService
      .getTrackingShipment(Number(this.idShipment))
      .subscribe({
        next: (response) => (this.trackingShipments = response),
        error: (err) => this.notification.showMessageError(err.message),
      });
  }

  getDocumentsUrl(urls?: string | null): string[] {
    if (urls) {
      return urls.split(',');
    }
    return [];
  }

  getDocumentName(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  downloadImageByUrl(urlFile: string): void {
    this.fileService.downloadFile(urlFile).subscribe((blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = this.getDocumentName(urlFile); // Replace with the desired filename
      link.click(); // Triggers the download
      window.URL.revokeObjectURL(url); // Clean up the URL after download
    });
  }
}
