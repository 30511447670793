import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from './../../../shared/notification/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import $, { error } from 'jquery';
import { IOrderDetailVM } from '../../order/models/order-detail-vm.model';
import { IOrderVM } from '../../order/models/order-vm.model';
import { ITrackingOrderVM } from '../../tracking-order/models/tracking-order-vm.model';
import { ExportDataTracking } from '../models/expoer-data.model';
import { DateRangePickerComponent } from './../../../shared/date-range-picker/date-piker-range/date-range-picker.component';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Pagination } from './../../../core/request/request.model';
import { ColumnType } from './../../../core/specification/columnType';
import { LogicalOperation } from './../../../core/specification/logicalOperation';
import { Operation } from './../../../core/specification/operation';
import { RequestSearchService } from './../../../core/specification/request-search.service';
import { RequestSearch } from './../../../core/specification/requestSearch';
import { QuerySearch } from '../../../core/specification/querySearch';
import { ICityVM } from '../../city/models/city-vm.model';
import { CitiesService } from '../../city/services/cities.service';
import { ICodeTrackingVM } from '../../code-tracking/models/code-tracking-vm.model';
import { CodeTrackingService } from '../../code-tracking/services/code-trakcing.service';
import { OrderDetailsService } from '../../order/services/order-details.service';
import { OrderService } from '../../order/services/order-service.service';
import { IUserVM } from '../../user/models/user-vm.model';
import { ITEMS_PER_PAGE } from './../../../core/config/pagination.constants';
import { PickupDetailsPopupComponent } from '../pickup-details-popup/pickup-details-popup.component';
import { UserVM } from '../../user/models/user-vm.model';
import { AccountService } from '../../user/services/user.service';
import { IndicatorOrderDetailsVM } from '../../order/models/indicator-shipment.model';
@Component({
  selector: 'milestone-pickup-details-ordre',
  templateUrl: './pickup-details-ordre.component.html',
  styleUrls: ['./pickup-details-ordre.component.scss'],
})
export class PickupDetailsOrdreComponent implements OnInit {
  
  readonly DateRangePickerComponent = DateRangePickerComponent;
  users: IUserVM[] = [];
  cities: ICityVM[] = [];
  trackingOrders: ITrackingOrderVM[] = [];
  dataexport: { [key: string]: any }[] = [];
  requestSearch?: RequestSearch | null;
  statutShipment: ICodeTrackingVM[] = [];
  pickup?: IOrderVM;
  operateurs: UserVM[] = [];

  totalItems = 0;
  pageSize = ITEMS_PER_PAGE;
  page=0;

  // sort
  predicate!: string;
  ascending!: boolean;

  isShowDivIf = false;

  displayedColumns: string[] = [
    'select',
    'Date de création',
    'Référence expédition',
    'Référence client',
    'Ville depart',
    'Ville arrivee',
    'Client',
    'Statut',
    'action',
  ];

  allColumns: string[] = this.displayedColumns;

  incident: string[]=[
    'PICKUP_ISSUE_PARCEL_UNAVAILABLE',
    'PICKUP_ISSUE_PARCEL_OUTSIZE',
    'PICKUP_ISSUE_SENDER_UNAVAILABLE',
    'PICKUP_ISSUE_SENDER_UNREACHABLE',
    'PICKUP_ISSUE_PARCEL_WITHOUT_LABEL',
    'PICKUP_ISSUE_CAR_ACCIDENT',
    'PICKUP_ISSUE_BROKEN_CAR',
    'PICKUP_ISSUE_WRONG_ADDRESS',
    'PICKUP_ISSUE_CANT_PARK',
    'PICKUP_ISSUE_CANT_ACCESS',
    'PICKUP_ISSUE_CANT_ACCESS',
    'PICKUP_REFUSED_BY_AGENT',
    'ORDER_DELIVER_ISSUE',
    'ORDER_REFUSED',
    'ORDER_PICKUP_ISSUE'
  ];

  visibleColumns = this.displayedColumns;
  valuesArray: any[] = [];

  dataSource = new MatTableDataSource<IOrderDetailVM>([]);
  selection = new SelectionModel<IOrderDetailVM>(true, []);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSelect) matSelect!: MatSelect;
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild(MatPaginator) set _paginator(paginator: MatPaginator) {
    this.paginator = paginator;
  }

  // filters
  idOrder = '';
  idShipement  = '';
  idParcelClient  = '';
  cityDeparture = '';
  cityArrival = '';
  clientName = '';
  selectedSatatus = '';

  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  isTouchUIActivated = false;

  indicatorOrderDetails?: IndicatorOrderDetailsVM;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private orderDetailsService: OrderDetailsService,
    private orderService: OrderService,
    private fb: FormBuilder,
    private notification: NotificationService,
    private requestSearchService: RequestSearchService,
    private citiesService: CitiesService,
    private codeTrackingService: CodeTrackingService,
    private userService: AccountService,
  ) {}

  ngOnInit(): void {
    this.idOrder= this.route.snapshot.paramMap.get('idOrdre') ?? '';
    this.getOrder(this.idOrder);
    this.getIndiatorOrderDeTails(this.idOrder);
    this.getOrderDetails();
    this.getTrackingOrdres(this.idOrder);
    this.getStatutShipment();
    this.getAllCity();
    this.getOperateur();

    $('.mat-mdc-text-field-wrapper').css({
      background: 'white',
      height: '46px',
      width:'100%'
    });
  }

  getOrder(idOrder: string): void {
    this.orderService.getOrderByIdOrder(idOrder).subscribe({
      next: (response) => (this.pickup = response),
      error: (errorRes) => this.notification.showMessageError(errorRes.message),
    });
  }

  onFiltersChange():void{
    this.page = 0;
    this.totalItems = 0;
    this.getOrderDetails();
    this.selection.clear();
  }

  getOrderDetails(): void {
    this.setQuery();
    const sort = ['createdDate,desc'];
    const pagination = this.setPagination(this.page, this.pageSize, sort);

    this.orderDetailsService.query(pagination, this.requestSearch).subscribe({
      next: (response) => {
        this.dataSource.data = response.content;
        this.totalItems = response.totalElements;
      },
      error: (errorRes) => this.notification.showMessageError(errorRes.message),
    });
  }

  getAllCity(): void {
    this.citiesService.getAllCities().subscribe({
      next: (response) => {
        this.cities = response;
      },
      error: () => {
        this.notification.showMessageError('Error loading Cities');
      },
    });
  }

  isVisible(column: string): boolean {
    return this.visibleColumns.includes(column);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  toggleDisplayDivIf(): void {
    this.isShowDivIf = !this.isShowDivIf;
  }

  onTableDataChange(event: any): void {
    this.page = Number(event.pageIndex);
    this.pageSize = event.pageSize;
    this.getOrderDetails();
    this.table.nativeElement.scrollIntoView();
    this.selection.clear();
  }

  replace002With0(input: any): any {
    return input.replace(/00212/g, '0');
  }

  getTrackingOrdres(idOrder: any): void {
    this.orderService.trackingOrdre(idOrder).subscribe({
      next: (response) => {
        this.trackingOrders = response;
        console.log(this.trackingOrders.length);
      },
      error: (err) => this.notification.showMessageError(err.message),
    });
  }

  getStatutShipment(): void {
    this.codeTrackingService.getAllCodeTrakings().subscribe({
      next: (response) => (this.statutShipment = response),
      error: () =>
        this.notification.showMessageError('Error loading sttaus order'),
    });
  }

  ExportToExcel(): void {
    if (this.selection.selected.length === 0) {
      alert('No rows selected');
      return;
    }

    const exportData = this.selection.selected.map((row) => {
      const dataObject: ExportDataTracking = {}; // Create an empty object to build

      if (this.isVisible('Date de création')) {
        dataObject.Date_de_création = this.convertTimestampToFormattedDate(
          row.createdDate
        );
      }

      if (this.isVisible('Référence expédition')) {
        dataObject.Ref_expédition = row.shipment.idShipment.toFixed(0);
      }

      if (this.isVisible('Ville depart')) {
        dataObject.Ville_de_départ = row.shipment.cityDeparture.cityName;
      }

      if (this.isVisible('Ville arrivee')) {
        dataObject.Ville_arrivé = row.shipment.cityArrival.cityName;
      }

      if (this.isVisible('Client')) {
        dataObject.Client =
          row.shipment.companySender === ''
            ? '-'
            : `${row.shipment.companySender} ${row.shipment.gsmSender}`;
      }

      if (this.isVisible('Statut')) {
        dataObject.Statut = row.shipment.currentStatus.description;
      }
      return dataObject; // Return the constructed object
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SelectedRows');
    XLSX.writeFile(workbook, 'ramassage.xlsx');
  }


  exportToPDF(): void {
    if (this.selection.selected.length === 0) {
      alert('No rows selected');
      return;
    }

    const doc = new jsPDF();

    // Create a table for the selected rows
    const exportData = this.selection.selected.map((row) => {
      const dataObject: ExportDataTracking = {};
      this.valuesArray = [];

      if (this.isVisible('Date de création')) {
        dataObject.Date_de_création = this.convertTimestampToFormattedDate(
          row.createdDate
        );
      }

      if (this.isVisible('Référence expédition')) {
        dataObject.Ref_expédition = row.shipment.idShipment.toFixed(0);
      }

      if (this.isVisible('Ville depart')) {
        dataObject.Ville_de_départ = row.shipment.cityDeparture.cityName;
      }

      if (this.isVisible('Ville arrivee')) {
        dataObject.Ville_arrivé = row.shipment.cityArrival.cityName;
      }

      if (this.isVisible('Client')) {
        dataObject.Client =
          row.shipment.companySender === ''
            ? '-'
            : `${row.shipment.companySender} ${row.shipment.gsmSender}`;
      }

      if (this.isVisible('Statut')) {
        dataObject.Statut = row.shipment.currentStatus.description;
      }
      return dataObject;
    });

    exportData.forEach((item) => {
      this.valuesArray.push(Object.values(item));
    });

    const columnNames = Object.keys(exportData[0]);

    autoTable(doc, {
      head: [columnNames],
      body: this.valuesArray,
    });

    // Save the PDF
    doc.save('ramassage.pdf');
  }

   getOperateur(): void {
    this.userService.getOperateurs().subscribe({
      next: (response) => (this.operateurs = response),
      error: () =>
        this.notification.showMessageError('error loading operators'),
    });
  }



  convertTimestampToFormattedDate(timestampInSeconds: number): string {
    const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds
    const dd: string = this.padZero(date.getDate());
    const mm: string = this.padZero(date.getMonth() + 1); // Months are 0-based
    const yyyy: string = date.getFullYear().toString();
    const hours: string = this.padZero(date.getHours());
    const minutes: string = this.padZero(date.getMinutes());
    const seconds: string = this.padZero(date.getSeconds());

    return `${dd}-${mm}-${yyyy} ${hours}:${minutes}:${seconds}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  // popup qualification
  openPopup(idOrder: string): void {
    const modalRef = this.modalService.open(PickupDetailsPopupComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      windowClass: 'modal-size', 
    });

    modalRef.componentInstance.idOrder = idOrder;
    modalRef.componentInstance.operateurs = this.operateurs;

    modalRef.closed.subscribe((reason) => {
      if (reason === 'ok') {
        setTimeout(() => {
          this.getOrder(idOrder);
          this.getOrderDetails();
          this.getTrackingOrdres(idOrder);
        }, 500);
      } else if (reason !== undefined) {
        console.log(reason);
        setTimeout(() => {
          this.openPopup(reason);
          this.getOrder(idOrder);
          this.getOrderDetails();
          this.getTrackingOrdres(idOrder);
        }, 500);
      }
    });
  }


  /* ==========================
       private methos
  ============================= */
  private setQuery(): void {
    this.requestSearchService.clearQueries();

    console.log(this.idOrder);
   if (this.idOrder !== '') {
      const query: QuerySearch = {
        column: 'idOrder',
        value: this.idOrder,
        operation: Operation.JOIN,
        columnType: ColumnType.STRING,
        operationJoin: Operation.EQUALS,
        joinTable: 'order',
        enumClassName: null,
      };
      this.requestSearch = this.getFilters(query);
    }

    // range date
    if (
      this.dateRange.value.start  && this.dateRange.value.end ) 
      {
      const fromDate = new Date(this.dateRange.value.start);
      const formatFromDate = this.formatDateToYYYYMMDD(fromDate) + ' 00:00:00';

      const toDate = new Date(this.dateRange.value.end);
      const formatToDate = this.formatDateToYYYYMMDD(toDate) + ' 23:59:59';

      let query: QuerySearch = {
        column: 'createdDate',
        value: formatFromDate,
        operation: Operation.GREATER_THAN_OR_EQUALS,
        columnType: ColumnType.DATE,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
      query = {
        column: 'createdDate',
        value: formatToDate,
        operation: Operation.LESS_THAN_OR_EQUALS,
        columnType: ColumnType.DATE,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };
      this.requestSearch = this.getFilters(query);
    }

    // in day
    if (
      this.dateRange.value.start !== null &&
      this.dateRange.value.end === null
    ) {
      const fromDate = new Date(this.dateRange.value.start);
      const formatFromDate = this.formatDateToYYYYMMDD(fromDate) + ' 00:00:00';

      const toDate = this.addDays(fromDate, 1);
      const formatToDate = this.formatDateToYYYYMMDD(toDate) + ' 00:00:00';

      let query: QuerySearch = {
        column: 'createdDate',
        value: formatFromDate,
        operation: Operation.GREATER_THAN_OR_EQUALS,
        columnType: ColumnType.DATE,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };
      this.requestSearch = this.getFilters(query);
      query = {
        column: 'createdDate',
        value: formatToDate,
        operation: Operation.LESS_THAN_OR_EQUALS,
        columnType: ColumnType.DATE,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };
      this.requestSearch = this.getFilters(query);
    }

    // id shipment
    if (this.idShipement) {
      const query: QuerySearch = {
        column: 'idShipment',
        value: this.idShipement,
        operation: Operation.JOIN,
        columnType: ColumnType.LONG,
        joinTable: 'shipment',
        operationJoin: Operation.EQUALS,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    if (this.idParcelClient) {
      const query: QuerySearch = {
        column: 'idParcelClient',
        value: this.idParcelClient,
        operation: Operation.JOIN,
        columnType: ColumnType.LONG,
        joinTable: 'shipment',
        operationJoin: Operation.LIKE,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // city departure
    if (this.cityDeparture !== '') {
      const query: QuerySearch = {
        column: 'cityName',
        value: this.cityDeparture,
        operation: Operation.JOIN,
        columnType: ColumnType.STRING,
        joinTable: 'cityDeparture',
        operationJoin: Operation.EQUALS,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // city arrival
    if (this.cityArrival !== '') {
      const query: QuerySearch = {
        column: 'cityName',
        value: this.cityArrival,
        operation: Operation.JOIN,
        columnType: ColumnType.STRING,
        joinTable: 'cityArrival',
        operationJoin: Operation.EQUALS,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // client
    if (this.clientName !== '') {
      let query: QuerySearch = {
        column: 'firstnameSender',
        value: this.clientName,
        operation: Operation.JOIN,
        columnType: ColumnType.STRING,
        joinTable: 'shipment',
        operationJoin: Operation.LIKE,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);

      query = {
        column: 'lastnameSender',
        value: this.clientName,
        operation: Operation.JOIN,
        columnType: ColumnType.STRING,
        joinTable: 'shipment',
        operationJoin: Operation.LIKE,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // sttaus shipment
    if (this.selectedSatatus !== '') {
      const query: QuerySearch = {
        column: 'codesTracking',
        value: this.selectedSatatus,
        operation: Operation.JOIN,
        columnType: ColumnType.ENUM,
        joinTable: 'currentStatus',
        operationJoin: Operation.EQUALS,
        enumClassName: 'CodesTracking',
      };

      this.requestSearch = this.getFilters(query);
    }
    console.log(JSON.stringify(this.requestSearch));
  }

  private formatDateToYYYYMMDD(date: Date): string {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(date.getDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
  }

  private getFilters(query: QuerySearch): RequestSearch {
    this.requestSearchService.addSearchQuery(query);

    return this.requestSearchService.buildRequestSearch(LogicalOperation.AND);
  }

  private setPagination(
    page: number,
    size: number,
    sort?: string[] | null
  ): Pagination {
    return {
      page,
      size,
      sort,
    };
  }

  private addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  private getIndiatorOrderDeTails(idOrder: string): void{
    this.orderDetailsService.getStatisticCount(idOrder).subscribe({
      next: (response) => this.indicatorOrderDetails = response,
      error: (err)=> this.notification.showMessageError(err.message)
    })
  }





}
