import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { JwtToken } from 'src/app/login/model/jwt-token';
import { LoginService } from 'src/app/login/service/login.service';
import { ApplicationConfigService } from '../config/application-config.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwtToken: JwtToken | null = this.storageService.getToken();

    if (jwtToken?.accessToken && jwtToken.refreshToken) {
      if (!this.tokenExpired(jwtToken.accessToken)) {
        const authReq = req.clone({
          headers: req.headers.set(TOKEN_HEADER_KEY, `Bearer ${jwtToken.accessToken}`),
        });
        return next.handle(authReq);
      }
      else{
        const authReq = req.clone({
          headers: req.headers.set(TOKEN_HEADER_KEY, `Bearer ${jwtToken.refreshToken}`),
        });
        return next.handle(authReq);
        // this.loginService.refreshToken()
        // .subscribe(
        //   res=>{
        //     if (res.accessToken){
        //       const authReq = req.clone({
        //         headers: req.headers.set(TOKEN_HEADER_KEY, `Bearer ${res.accessToken}`),
        //       });
        //       console.log('intercept ****** tokenExpired, refesh token ****  authReq : ', authReq);
        //       return next.handle(authReq);
        //     }
        //     return next.handle(req);
        //   }
        // );
        // console.log('intercept ****** esle no token**** Req : ', req);        
        // return next.handle(req);
      }
      
    } else {
      
      // Handle cases where there's no token (e.g., redirect to login)
      this.router.navigateByUrl("");
      return next.handle(req);
    }
  }



  /**
   * the function for check the jwt is expired or not
   * @param token : jwt
   * @returns true or false : if the jwt(token) is expired return true, if not return false
   */
  private tokenExpired(token: string): boolean {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
