import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateAgencyVM } from '../models/create-agency-vm.model';
import { AgenciesService } from '../services/agencies.service';

@Component({
  selector: 'milestone-create-agency-from',
  templateUrl: './create-agency-from.component.html',
  styleUrls: ['./create-agency-from.component.scss'],
})
export class CreateAgencyFromComponent implements OnInit {

  createAgencyError = false;
  errorAgency = '';
  loading = false;

  @Output() formSubmit = new EventEmitter<any>();
  @Output() formCancel = new EventEmitter<void>();

  userForm =  this.fb.group({
    agecnyId: ['', Validators.required],
    description: [''],
    agencyName: ['', Validators.required],
    addressAgency: ['', Validators.required],
    position: ['', Validators.required],
    confirmation: [false, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private agenciesService: AgenciesService
  ) {}

  ngOnInit(): void {}

  onSubmit(): void {
    if (this.userForm.valid && this.userForm.value.confirmation) {
      this.loading = true;

      this.agenciesService
        .createAgency(
          new CreateAgencyVM(
            this.userForm.value.agecnyId,
            this.userForm.value.description,
            this.userForm.value.agencyName,
            this.userForm.value.addressAgency,
            this.userForm.value.position
          )
        )
        .subscribe(
          (response) => {
            console.log('response ', JSON.stringify(response));
            this.notification.showMessageSuccess('Agency created successfully');
            this.loading = false;
            this.formSubmit.emit(this.userForm.value);
          },
          (error) => {
            this.createAgencyError = true;
            this.errorAgency = error.error.message;
            this.loading = false;

            this.notification.showMessageError('Error creating Agency');
          }
        );
    } else {
      this.notification.showMessageError('Please fill in all required fields');
    }
  }

  cancel(): void {
    this.formCancel.emit();
  }
}
